<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw.with_center')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span
          class="nav-right"
          @click="$router.push({ path: '/WithdrawRecord' })"
          >{{ $t("withdraw.with_record") }}</span
        >
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span
          >{{ $t("withdraw.with_money") }} ({{ $t("reservation.unit") }})</span
        >
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">{{ $t("reservation.unit") }}</span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{
            $t("index.all")
          }}</span>
        </div>
        <div style="display: none;">
            <van-field
              v-model="fieldValue"
              is-link
              readonly
              :label="$t('setting.band_name')"
              :placeholder="$t('setting.band_name_tip')"
              @click="show = true"
            />
          </div>
        <div class="information">
          <div class="balance">
            <span>{{ $t("my.balance") }}：</span>
            <span class="number"
              >{{ this.userInfo.money }}{{ $t("reservation.unit") }}</span
            >
          </div>
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px">
                <p>
                  1.{{
                    $t("withdraw.singleall", {
                      min: getformatter(withdrawRole.min),
                      max: getformatter(withdrawRole.max),
                    })
                  }}
                </p>
               
                <p>
                  2.{{ $t("withdraw.singlenum", { num: withdrawRole.num }) }}
                </p>
                <p>3.{{ $t("withdraw.with_tip") }}</p>
              </div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{ $t("withdraw.limit_desc") }}
              </template>
            </van-popover>
          </div>
        
        </div>
      </div>
      <div
        v-if="userInfo.withdrawStatus === 0"
        style="
          width: 100%;
          padding: 20px 10px;
          background-color: #fff;
          color: red;
        "
      >
        {{ userInfo.withdrawReason }}
      </div>
      <van-button
        v-else
        class="withdraw_btn"
        type="default"
        @click="doWithdraw()"
      >
        {{ $t("withdraw.immediately_withdraw") }}</van-button
      >
    </div>
    <div
      style="
        position: fixed;
        width: 100%;
        bottom: 0px;
        left: 0px;
        background-color: #fff;
      "
      v-show="show"
    >
      <van-picker
        show-toolbar
        :columns="options1"
        @confirm="onConfirm"
        @cancel="onCancel"
        :confirm-button-text="$t('setting.ok')"
        :cancel-button-text="$t('setting.cancel')"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      fieldValue: null,
      cascaderValue: null,
      options: [],
      options1: [],
      showPopover: false,
      withdraw_money: "",
      userInfo: {},
      withdrawRole: {},
    };
  },
  methods: {
    onCancel() {
      this.show = false;
    },
    onConfirm(value, index) {
      //Toast(`当前值：${value}, 当前索引：${index}`);
      this.fieldValue = this.options[index].name;
      this.cascaderValue = this.options[index].code;
      this.show = false;
    },
    getformatter(val) {
      let result = val;
      if (!(isNaN(val) || val < 1000)) result = val / 1000 + "K";
      return result;
    },
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserWithdrawRole() {
      this.$http({
        method: "get",
        url: "user_get_withdraw_role",
      }).then((res) => {
        if (res.code === 200) {
          this.withdrawRole = res.data;
          this.options = this.withdrawRole.bank;
          this.options1 = []
          this.options.forEach(element => {
            this.options1.push(element.name)
          });
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw() {
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t("setting.correct_money"));
        return false;
      } else {
        this.$http({
          method: "post",
          data: { money: this.withdraw_money },
          url: "user_set_withdraw",
        }).then((res) => {
          if (res.code === 200) {
            location.reload();
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      }
    },
    withdrawInfo() {
      this.showPopover = true;
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel,
::v-deep .van-picker__confirm {
  padding: 0 20px;
  font-size: 35px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all {
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
}
.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number {
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #e6c3a1, #7e5678);
}
</style>
