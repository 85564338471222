<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="payments">
        <div v-for="(item, index) in items" :key="index" style="width: 100%;background-color: #fff;border-radius: 8px;line-height: 0.2;margin-bottom: 10px;" @click="toR(item)">
				<img src="../images/user/tit.png" style="width: 60px;float: left;margin-left: 10px;">
                <div style="float: left;margin-left: 10px;">
                    <p style="font-weight: 600;">{{ item.name }}</p>
				<span style="color: #535353;">{{ Number(item.min.match(/^\d+(?:\.\d{0,2})?/)) }} - {{ Number(item.max.match(/^\d+(?:\.\d{0,2})?/)) }}</span>
            </div>
				<img src="../images/user/arrow.png" style="width: 25px;float: right;margin-top: 13px;margin-right: 10px;">
			</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RechargeLst",
  data(){
    return {
        items:[]
    }
  },
  mounted() {
    this.$http({
      method: "get",
      url: "rechargeinfo",
      data: { id: this.$route.query.id },
    }).then((result) => {
      this.items = result.data;
      
    });
  },
  methods: {
    back() {
      return window.history.back();
    },
    toR(item){
        this.$router.push({path: "/ToRecharge", query: {id: JSON.stringify(item)}})
    },
  },
};
</script>
<style scoped>
.payments {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 10px;
	}

	.payments>img {
		padding: 10px;
		width: 50%;
		height: 200px;
	}
</style>